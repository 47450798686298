<template>
  <v-main class="grey lighten-5">
    <div class="py-16 text-center" v-if="isLoading">
      <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <v-container class="pt-0 pb-16" v-if="!isLoading && topics">
      <v-breadcrumbs :items="items" divider="/" class="px-2"></v-breadcrumbs>
      <v-row class="mb-8">
        <v-col cols="12" class="pa-5">
          <h3 class="text-h4 primary--text d-inline-block mb-8">
            {{ topic.name }}
          </h3>
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="6"
              lg="4"
              v-for="item in articlesOrdered"
              :key="item.uid"
            >
              <ArticleItem :item="item" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-pagination
        v-if="totalPages > 1"
        v-model="page"
        :length="totalPages"
        :total-visible="7"
      ></v-pagination>
    </v-container>
  </v-main>
</template>
<script>
import ArticleItem from "../components/ArticleItem";
import { getTopicsPath, getArticlesPath } from "../router/index";

export default {
  props: {
    topics: {
      type: Array,
    },
  },
  data: () => ({
    isLoading: true,
    page: 1,
    tags: null,
    pageSize: 9,
    articles: {},
  }),
  components: { ArticleItem },
  watch: {
    "$i18n.locale"() {
      this.loadContent();
    },
    topicsPath() {
      return getTopicsPath(this.$i18n.locale);
    },
    articlesPath() {
      return getArticlesPath(this.$i18n.locale);
    },
    page() {
      this.loadContent();
    },
    "$route.params.tag"() {
      this.loadContent();
    },
  },
  computed: {
    totalPages() {
      return this.articles.total_pages;
    },
    locale() {
      return this.$i18n.locale === "es" ? "es-es" : "en-us";
    },
    topic() {
      return this.topics.find((c) => c.slug == this.$route.params.tag);
    },
    items() {
      return [
        {
          text: this.$i18n.t("learn"),
          disabled: false,
          to: "/",
        },
        {
          text: this.topic ? this.topic.name : "",
          disabled: true,
        },
      ];
    },
    articlesOrdered() {
      return this.articles.results.sortBy("position");
    },
  },
  methods: {
    setTitle() {
      this.$title = this.topic.name;
    },
    async loadContent() {
      this.isLoading = true;
      await this.getArticles();
      this.isLoading = false;
      this.setTitle();
    },
    async getArticles() {
      const document = await this.$prismic.client.query(
        this.$prismic.Predicates.any("document.tags", [this.$route.params.tag]),
        { lang: this.locale, page: this.page, pageSize: this.pageSize }
      );
      this.articles = document;
    },
  },
  created() {
    this.loadContent();
  },
  beforeRouteUpdate(to, from, next) {
    this.tags = this.$route.query.tags;
    this.loadContent();
    next();
  },
};
</script>